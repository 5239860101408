import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="Incursus" link="https://www.vedan.tk/app/incursus" bg="linear-gradient(55deg, #000 0%, #3f3f3f 100%)" mdxType="ProjectCard">
  Incursus is a personal task manager that prevents the planning trap and promotes taking action.
    </ProjectCard>
    <ProjectCard title="GMA App" link="https://www.vedan.tk/app/gma" bg="linear-gradient(to right, #662DFF 0%, #2020FF 100%)" mdxType="ProjectCard">
  Worked with Gastrolab India to design and develop Gastric Motility Assessment Tool
    </ProjectCard>
    <ProjectCard title="Choreographer" link="https://www.vedan.tk/app/choreographer" bg="linear-gradient(to right, #F055FF 0%, #FF5555 100%)" mdxType="ProjectCard">
  Easily generate mockup videos for your app
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      