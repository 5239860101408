import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Hey! I am Vedant.`}</h1>
    <p>{`I'm a software developer from Pune, IN.`}</p>
    <p>{`I design and develop cool things. `}<a parentName="p" {...{
        "href": "https://vedan.tk/app"
      }}>{`Take a look :)`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      