import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About Me`}</h2>
    <blockquote>
      <p parentName="blockquote">{`The best way to get to know me is over a good cup(mug?) of coffee, but this is a good start.`}</p>
    </blockquote>
    <p>{`My name is Vedant Karandikar. I'm 21( almost 22) years old. I'm a software engineer and I love making useful apps and tools.
Most of my hardwork goes into making apps which will eventually let me be lazy.
When I'm not using my super powers to write code, I'm usually `}<strong parentName="p">{`listening to music, reading, or playing chess.`}</strong>{`.`}</p>
    <p>{`We'll definitely get along if you're interested in:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Artificial Intelligence`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Self-Driving Cars`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Data Analysis`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Ethical(?) Hacking`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`F.R.I.E.N.D.S.`}</strong></li>
      <li parentName="ul">
        <h5 parentName="li">{`me 😉`}</h5>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      